.sidebar {
  align-self: baseline;
  position: sticky;
  top: 70px;
  overflow-y: scroll;
  height: calc(100vh - 75px);

  // -ms-overflow-style: none;
  // /* Internet Explorer 10+ */
  // scrollbar-width: none;
  // /* Firefox */

  @media screen and (max-width: 960px) {
    display: none;
    height: unset;
  }
}

.submenu {
  display: inline-block;
  position: fixed;
  top: unset;
  overflow-y: auto;
  width: 160px;
  z-index: 100;
  border-radius: 0px;
  background-color: white;
  // transition: top 0.1s ease;
  @media screen and (max-width: 960px) {
    display: none;
    height: unset;
  }
}

// .sidebar::-webkit-scrollbar {
//   display: none;
//   /* Safari and Chrome */
// }
